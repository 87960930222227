import { Route, Routes } from 'react-router-dom';
import { RouteChangeTracker } from './hooks/RouteChangeTracker';

import Layout from './components/Layout';
import Home from "./pages/Home";
import Lookbook from './pages/Lookbook';
import PreviewCatalogue from "./pages/PreviewCatalogue";
import Exhibition from './pages/Exhibition';

import 'antd/dist/antd.css';

import ReactGA from "react-ga";
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);

function App() {

    RouteChangeTracker();

    return (
        <>
            <Routes>
                <Route element={<Layout/>}>
                    <Route path='/' element={<Home/>}/>
                    <Route path='/lookbook' element={<Lookbook/>}/>
                    <Route path='/preview-catalogue' element={<PreviewCatalogue/>}/>
                    <Route path='/exhibition' element={<Exhibition/>}/>
                </Route>
            </Routes>
        </>
    );
}

export default App;