import React, { useState, useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import "./style.css";

import { run } from "../../lib/play/run";
import CameraRGB from "../../lib/play/programs/camera/camera_rgb";

const DEFAULT_FONT_SIZE = 10;
const MAX_FONT_SIZE = 42;
const MIN_FONT_SIZE = 10;
const STEP = 8;

const CanvasControl = ({ fontSize, setFontSize }) => {
    const magnify = () => {
        setFontSize(fontSize => fontSize >= MAX_FONT_SIZE ?
            fontSize :
            fontSize + STEP
        );
    }
    const reduce = () => {
        setFontSize(fontSize => fontSize <= MIN_FONT_SIZE ?
            fontSize :
            fontSize - STEP
        );
    }

    return (
        <div className="canvas-tools">
            <button className="btn98" onClick={magnify} disabled={fontSize >= MAX_FONT_SIZE}>+</button>
            <button className="btn98" onClick={reduce} disabled={fontSize <= MIN_FONT_SIZE}>-</button>
        </div>
    )
}

const Capture = React.memo((props) => {
    const onClick = async () => {
        const dataUrl = props.targetRef.current.toDataURL('image/png');
        const blob = await (await fetch(dataUrl)).blob();
        const shareData = {
            files: [new File([blob], 'tint.png', {type: 'image/png'})]
        }
        if (navigator.canShare && navigator.canShare(shareData)) {
            navigator.share(shareData);
        } else {
            var link = document.createElement('a');
            link.href = dataUrl;
            link.download = 'tint.png';
            link.click();
        }
    }
    return (
        <button className="capture btn98" onClick={onClick}>Capture</button>
    );
})

const Canvas = (props) => {
    const [fontSize, setFontSize] = useState(DEFAULT_FONT_SIZE);
    const nodeRef = useRef();

    useEffect(() => {
        const node = nodeRef.current;

        const program = new CameraRGB(props.facingMode, props.setLoading, isMobile);
        run(program, { element: node, renderer: "canvas" }).then(function (e) {
            console.log(e);
        }).catch(function (e) {
            console.warn(e.message);
            console.log(e.error);
        });
    }, [props.facingMode]);

    return (
        <div className="canvas-wrapper">
            <canvas id='camera' ref={nodeRef} style={{ 'fontSize': fontSize + 'px' }}></canvas>
            <CanvasControl fontSize={fontSize} setFontSize={setFontSize} />
            <Capture targetRef={nodeRef}/>
            { props.children }
        </div>
    )
}
export default Canvas;