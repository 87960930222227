import { toCanvas } from 'html-to-image';

export const shareImg = (blob) => {
    const fileName = 'tint.jpg'
    const shareData = {
        files: [new File([blob], fileName, {type: 'image/jpeg'})]
    }
    // TODO: check canShare
    if (navigator.canShare && navigator.canShare(shareData)) {
        navigator.share(shareData).then(() => {
            console.log('shared')
        })
    } else {
        var link = document.createElement('a');
        toCanvas(document.getElementById('camera')).then(canvas => {
            link.href = canvas.toDataURL();
            link.download = fileName;
            link.click();
        });
    }
}

export const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
        mimeType = arr[0].match(/:(.*?);/)[1],
        decodedData = atob(arr[1]),
        lengthOfDecodedData = decodedData.length,
        u8array = new Uint8Array(lengthOfDecodedData);
    while (lengthOfDecodedData--) {
        u8array[lengthOfDecodedData] = decodedData.charCodeAt(lengthOfDecodedData);
    }
    return new File([u8array], filename, { type: mimeType });
};

export const importAllImages = r => {
    return r.keys().map((key, idx) => ({
        id: idx,
        src: key.replace('./', '')
    }));
}