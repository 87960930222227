const MAX_VELOCITY = 150;
const MIN_VELOCITY = 2;
const ACCELERATION = -3;

export default class FA22SlideShow {
    constructor(node, repeat) {
        this.node = node;
        this.repeat = repeat;
        this.mainImage = node.querySelector(".main-image");
        this.slides = node.querySelector(".controller ul");
        this.slideWidth = this.slides.querySelector(".controller ul li").offsetWidth;

        this.playBtn = document.getElementById("play");
        // this.playState = "play";

        this.mainIndex = 0;
        this.idxBuffer = new Set();

        this.hold = false;
        this.startX  = 0;
        this.offsetX = -(this.slides.offsetWidth / repeat) + this.node.offsetWidth*0.5 - 2;
        this.prevOffsetX = 0;
        this.limit   = 200;
        this.flag = false;
        this.slides.style.transform = `translateX(${this.offsetX})`;

        this.observeSlides();

        this.slide = false;
        this.timeLapse = 0;
        this.d1 = 0;  // curr distance
        this.d2 = 0;  // prev distance
        this.v0 = 0;
        this.forward = false;

        this.handleMousedown  = this.mousedown.bind(this);
        this.handleMousemove  = this.mousemove.bind(this);
        this.handleMouseup    = this.mouseup.bind(this);
        this.handleMouseleave = this.mouseleave.bind(this);

        this.handleTouchStart = this.touchstart.bind(this);
        this.handleTouchMove  = this.touchmove.bind(this);
        this.handleTouchEnd   = this.touchend.bind(this);

        this.handleResize = this.resize.bind(this);

        this.node.addEventListener("mousedown", this.handleMousedown, false);
        this.node.addEventListener("mousemove", this.handleMousemove, false);
        this.node.addEventListener("mouseup", this.handleMouseup, false);
        this.node.addEventListener("mouseleave", this.handleMouseleave, false);

        this.node.addEventListener("touchstart", this.handleTouchStart, false);
        this.node.addEventListener("touchmove", this.handleTouchMove, false);
        this.node.addEventListener("touchend", this.handleTouchEnd, false);

        window.addEventListener("resize", this.handleResize, false);

        requestAnimationFrame(this.loop.bind(this));
    }

    handleIntersection(entries) {
        let maxRatio = 0;
        this.slides.querySelector(`[data-index="${this.mainIndex}"] img`).style.border="none";
        for(let entry of entries) {
            let iRatio = entry.intersectionRatio;
            if(entry.isIntersecting) {
                this.idxBuffer.add(entry.target.dataset.index);
            } else {
                this.idxBuffer.delete(entry.target.dataset.index);
            }
            if (entry.isIntersecting && iRatio > 0) {
                if (iRatio && iRatio > maxRatio) {
                    maxRatio = iRatio;
                    this.mainIndex = entry.target.dataset.index;
                }
            }
        }

        if(this.idxBuffer.size < 2) this.mainIndex = parseInt([...this.idxBuffer][0]) || this.mainIndex;
        let mainSlide = this.slides.querySelector(`[data-index="${this.mainIndex}"] img`);
        if(this.mainIndex === 30) this.flag = true;
        if(this.flag) this.mainImage.style.backgroundImage = `url(${mainSlide?.src})`;

        /* for video */
        // if(parseInt(this.mainIndex%54)===0) {
        //     this.mainImage.style.backgroundImage = null;
        //     document.querySelector(".iframe-wrapper").classList.add("show");
        // } else {
        //     document.querySelector(".iframe-wrapper").classList.remove("show");
        // }
    }

    observeSlides() {
        const barWidth    = this.slideWidth;
        const mg          = (this.node.offsetWidth - barWidth) * 0.5;
        const options = { rootMargin: `0px -${mg}px 0px -${mg}px`, threshold: [0.55] };
        this.observer = new IntersectionObserver(this.handleIntersection.bind(this), options);
        this.slides.querySelectorAll("li").forEach(slide => { this.observer.observe(slide) });
    }

    band() {
        const maxLimit = this.limit > 0 ? -this.limit : this.limit;
        const minLimit = -(this.slides.offsetWidth - this.node.offsetWidth - this.limit);
        if (this.offsetX < minLimit) {
            this.slides.appendChild(this.slides.firstElementChild);
            this.slides.style.transform = `translateX(${this.offsetX + this.slideWidth}px)`;
            this.offsetX += this.slideWidth;
        } else if (this.offsetX > maxLimit) {
            this.slides.insertBefore(this.slides.lastElementChild, this.slides.firstElementChild);
            this.slides.style.transform = `translateX(${this.offsetX - this.slideWidth}px)`;
            this.offsetX -= this.slideWidth;
        }
    }

    movement(t) {
        return this.v0*t + 0.5*ACCELERATION*Math.pow(t, 2);
    }

    loop() {
        if(this.slide) {
            let d = this.movement(this.timeLapse + 1) - this.movement(this.timeLapse);
            d = this.forward ? -d : d;
            this.slides.style.transform = `translateX(${this.offsetX + d}px)`;
            this.offsetX += d;
            if(Math.abs(d) < MIN_VELOCITY) {
                this.slide = false;
            }
        } else if(!this.hold && this.timeLapse > 2) {
            --this.offsetX;
            this.slides.style.transform = `translateX(${this.offsetX}px)`;
            this.timeLapse = 0;
        } else if(this.hold) {
            const diff = this.d1 - this.d2;
            this.v0 = Math.min(Math.abs(diff), MAX_VELOCITY);
            this.forward = diff < 0 ? true : false;
        }
        this.timeLapse++;

        this.band();

        requestAnimationFrame(this.loop.bind(this));
    }

    resize() {
        this.slides.querySelectorAll("li").forEach(slide => {
            this.observer.unobserve(slide);
        });

        this.observeSlides();
    }

    mousedown(e) {
        this.hold  = true;
        this.slide = false;
        this.startX = e.clientX;
        this.d1 = e.clientX;
        this.d2 = e.clientX;
    }

    mouseup(e) {
        this.hold = false;
        this.offsetX = e.clientX - this.startX + this.offsetX;
        if(this.v0 > MIN_VELOCITY) {
            this.slide = true;
            this.timeLapse = 0;
        }
    }

    mousemove(e) {
        if (this.hold) {
            const d = e.clientX - this.startX + this.offsetX;
            this.slides.style.transform = `translateX(${d}px)`;
            this.d2 = this.d1;
            this.d1 = d;
        }
    }

    mouseleave(e) {
        if(this.hold) {
            this.hold = false;
            this.offsetX = e.clientX - this.startX + this.offsetX;
        }
    }

    touchstart(e) {
        this.hold = true;
        this.slide = false;
        // e.touches[0].clientX
        this.startX = e.touches[0].pageX;
        this.d1 = e.touches[0].pageX;
        this.d2 = e.touches[0].pageX;
    }

    touchmove(e) {
        if (this.hold) {
            const d = e.touches[0].pageX - this.startX + this.offsetX;
            this.slides.style.transform = `translateX(${d}px)`;
            this.prevOffsetX = d;
            this.d2 = this.d1;
            this.d1 = d;
        }
    }

    touchend(e) {
        this.hold = false;
        this.offsetX = this.prevOffsetX;
        if(this.v0 > MIN_VELOCITY) {
            this.slide = true;
            this.timeLapse = 0;
        }
    }

    clickPlayBtn(e) {
        //
    }

    cleanup() {
        this.slides.querySelectorAll("li").forEach(slide => { this.observer.unobserve(slide)});

        this.node.removeEventListener("mousedown", this.handleMousedown, false);
        this.node.removeEventListener("mousemove", this.handleMousemove, false);
        this.node.removeEventListener("mouseup", this.handleMouseup, false);
        this.node.removeEventListener("mouseleave", this.handleMouseleave, false);

        this.node.removeEventListener("touchstart", this.handleTouchStart, false);
        this.node.removeEventListener("touchmove", this.handleTouchMove, false);
        this.node.removeEventListener("touchend", this.handleTouchEnd, false);

        window.removeEventListener("resize", this.handleResize, false);
    }
}