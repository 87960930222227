import "./style.css";

const FaceBtn = (props) => {
    // TODO: 휴대폰일 때만 작동하게
    const onClick = () => {
        props.facingMode === 'user'
            ? props.setFacingMode('environment')
            : props.setFacingMode('user');
    }

    return (
        <button className="face-btn btn98" onClick={onClick}>
            { props.facingMode === "user" ? "Back Camera" : "Front Camera" }
        </button>
    )
}

export default FaceBtn;