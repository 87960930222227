import React, { useState, useRef } from "react";
import { useIntersection } from "../../hooks/useIntersectionObserver";

import "./style.css";

import images from "./data.json";

const GridItem = ({ image }) => {
    const [loaded, setLoaded] = useState(false);
    const [isInView, setIsInView] = useState(false);
    const liRef = useRef();

    useIntersection(liRef, () => {
        setIsInView(true);
    }, {});

    return (
        <li ref={liRef}>
            <div className="pixelator" style={{ backgroundColor: loaded ? "transparent" : "rgb("+image.rgb+")" }}>
            {
                isInView &&
                <img style={{opacity: loaded ? 1 : 0, height: loaded ? "auto" : 0}}
                    src={require(`../../assets/images/prvw/${image.name}`)}
                    onLoad={() => setLoaded(true)}
                    data-index={image.idx}
                    alt=""
                />
            }
            </div>
        </li>
    )
}

const CatalogueGrid = () => {
    const gridRef = useRef();
    const nodeRef = useRef();
    const cnt = useRef(0);

    const zoom = e => {
        const grid = gridRef.current;
        const node = nodeRef.current;

        // ####### TODO : fix click evt occur as current page navigated #######
        if(e.target.classList.contains("pixelator")) return;

        if(node.classList.contains("zoomed")) {
            node.classList.remove("zoomed");
            const screenWidth = window.innerWidth;
            const imageIndex = e.target.dataset.index;
            const imageWidth = screenWidth<1140 ? node.offsetWidth * 0.2 : node.offsetWidth * 0.1;
            const row = screenWidth<1140 ? Math.floor(imageIndex/5) : Math.floor(imageIndex/10);
            let pd = window.getComputedStyle(node).getPropertyValue('padding');
            pd = parseInt(pd.split("px")[0]);
            const offsetY = (grid.offsetHeight - imageWidth)*0.5;
            let result = pd + row*imageWidth - offsetY;
            result = Math.max(result, pd);
            result = Math.min(result, node.offsetHeight - grid.offsetHeight);
            grid.scrollTo(0, result);
        } else {
            let pd = window.getComputedStyle(node).getPropertyValue('padding');
            pd = parseInt(pd.split("px")[0]);
            const imageIndex = e.target.dataset.index;
            const imageWidth = window.innerWidth<1140 ? node.offsetWidth : node.offsetWidth*0.6;
            const offsetY = (grid.offsetHeight - imageWidth)*0.5;
            
            node.classList.add("zoomed");
            // console.log("zoomed-in index : ", e.target.dataset.index, "padding-top : ", pd, "image width : ", imageWidth);
            grid.scrollTo(0, pd + imageIndex*imageWidth - offsetY);
        }
        cnt.current++;
    }

    return (
        <div className="catalog-grid" ref={gridRef}>
            <div className="catalog-grid-wrapper">
                <ul ref={nodeRef} onClick={zoom}>
                    {
                        images.map(image =>
                            <GridItem key={image.idx} image={image} />
                        )
                    }
                </ul>
            </div>
        </div>
    )
}

export default CatalogueGrid;