import SlideShow from "../components/SlideShow/component";
import PlayBtn from "../components/PlayButton/component";

const Lookbook = () => {
    return (
        <>
            <SlideShow />
            {/* <PlayBtn /> */}
        </>
    )
};

export default Lookbook;