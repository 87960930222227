import {useEffect, useState} from "react";
import { isMobile, isIOS } from "react-device-detect";

import Canvas from "../components/Canvas";
import FaceBtn from "../components/FaceBtn/component";
import Loading from "../components/Loading/component";

const Home = () => {
    const [facingMode, setFacingMode] = useState('user');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
    }, [facingMode]);

    return (
        <>
            <Canvas facingMode={facingMode} setLoading={setLoading}>
                { loading && <Loading/> }
            </Canvas>
            { isMobile && isIOS && <FaceBtn facingMode={facingMode} setFacingMode={setFacingMode}/> }
        </>
    );
}

export default Home;