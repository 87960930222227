// https://betterprogramming.pub/lazy-loading-images-with-intersection-observer-in-react-ad6135f1ca59

import { useEffect } from "react";

// weak reference -> allows garbage collection
let listenerCallbacks = new WeakMap();

let observer;

const handleIntersections = entries => {
    for(let entry of entries) {
        if(listenerCallbacks.has(entry.target)) {
            let cb = listenerCallbacks.get(entry.target);

            // when entry is on viewport, unobserve it
            if (entry.isIntersecting || entry.intersectionRatio > 0) {
                observer.unobserve(entry.target);
                listenerCallbacks.delete(entry.target);
                cb();
            }
        }
    }
}

const getIntersectionObserver = (options) => {
    if(observer === undefined) {
        observer = new IntersectionObserver(handleIntersections, {
            ...options,
            threshold: '0.15'
        });
    }
    return observer;
}

export function useIntersection(elem, callback, options) {
    useEffect(() => {
        let target = elem.current;
        let observer = getIntersectionObserver(options);
        listenerCallbacks.set(target, callback);
        observer.observe(target);

        return () => {
            listenerCallbacks.delete(target);
            observer.unobserve(target);
        };
    }, []);
}