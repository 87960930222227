import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import { useMediaNav } from "../../hooks/useMediaNav";

import "./style.css";

const links = [
    {
        path: "/",
        label: "ASCII Cam"
    },
    {
        path: "/lookbook",
        label: "FA22 Collection"
    },
    {
        path: "/preview-catalogue",
        label: "Preview Catalog"
    },
    {
        path: "/exhibition",
        label: "Exhibition"
    }
]

const Nav = () => {
    const menuRef = useRef();

    useMediaNav(menuRef);

    const shareUrl = async () => {
        const siteUrl = "https://fa22.thisisneverthat.com/";
        const shareData = {
            title: "thisisneverthat FA22",
            text: "",
            url: siteUrl
        };

        if (navigator.canShare && navigator.canShare(shareData)) {
            navigator.share(shareData);
        } else {
            await navigator.clipboard.writeText(siteUrl);
            alert("URL copied!");
        }
    }

    return (
        <nav className="header-nav">
            <ul className="nav-links">
                <li className="menu" ref={menuRef}>
                    <button>Menu</button>
                    <ul className="menu-nav-links">
                    {
                        links.map((link, index) =>
                            <li key={index}>
                                <NavLink
                                    to={link.path}
                                    className={({ isActive }) => (isActive ? "selected" : "")}
                                >
                                    { link.label }
                                </NavLink>
                            </li>
                        )
                    }
                    </ul>
                </li>
                <li><a href="https://thisisneverthat.com" target="_blank">Shop</a></li>
                <li><a href="https://www.instagram.com/thisisneverthat/" target="_blank">Instagram</a></li>
                <li><button onClick={shareUrl} className="share-btn">Share</button></li>
            </ul>
        </nav>
    )
}

const Header = () => {
    return (
        <header>
            <div className="header-wrapper">
                <NavLink to={"/"}>
                    <h1 className="bg-dark">thisisneverthat® FA22 OFFLINE ONLY</h1>
                </NavLink>
                <Nav/>
            </div>
        </header>
    )
}

export default Header;