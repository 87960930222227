import React, { useEffect, useRef } from "react";
import FA22SlideShow from "../../lib/fa22slideshow";

import { isMobile } from "react-device-detect";
import { importAllImages } from "../../utils";


import "./style.css";

const REPEAT_NUM = 5;

const SlideShow = () => {
    const nodeRef = useRef();
    let temp = Array(REPEAT_NUM).fill(0).map(el => importAllImages(require.context('../../assets/images/FA22_JPG', false, /\.jpg$/)));
    const images = [].concat(...temp);

    useEffect(() => {
        const node = nodeRef.current;
        const FA22Slide = new FA22SlideShow(node, REPEAT_NUM);

        return () => {
            FA22Slide.cleanup();
        };
    }, []);

    return (
        <div className="slide-show__wrapper" ref={nodeRef}>
            <div style={{ backgroundImage: `url(${require(`../../assets/images/FA22_JPG/${images[0].src}`)})` }}
            className={`main-image grabbable ${isMobile ? "mobile" : ""}`}>
                {/* <div className="iframe-wrapper">
                    <iframe className="tnt-nb-video"
                        src="https://www.youtube.com/embed/5AfepoN3zLU" 
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                    </iframe>
                </div> */}
            </div>
            
            <div className="controller">
                <ul>
                    {
                        images.map((image, index) =>
                            <li key={index} data-index={index}>
                                <img src={require(`../../assets/images/FA22_JPG/${image.src}`)} alt="" />
                            </li>
                        )
                    }
                </ul>
            </div>
        </div>
    );
}

export default SlideShow;