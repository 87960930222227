import { isMobile } from "react-device-detect";

import loadingPc from '../../assets/videos/loading_pc.mp4';
import loadingMobile from '../../assets/videos/loading_mobile.mp4';

import "./style.css";

const Loading = () => {
    return (
        <div className="loading-wrapper">
            <video id="loading" autoPlay muted playsInline loop>
                <source src={ isMobile ? loadingMobile : loadingPc } type="video/mp4"/>
            </video>
        </div>
    )
}

export default Loading;