import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function useMediaNav(navRef) {
    const navigate = useNavigate();

    useEffect(() => {
        const nav = navRef.current;
        const btn = nav.querySelector("button");

        function openMenu(e) {
            nav.classList.add("open");
        }

        function closeMenu(e) {
            nav.classList.remove("open");
        }

        function mobileCloseMenu(e) {
            nav.classList.remove("open");
            e.stopImmediatePropagation();
        }

        function toggleMenu(e) {
            if(e.target===btn && nav.classList.contains("open")) {
                nav.classList.remove("open");
            } else if(e.target===btn && !nav.classList.contains("open")) {
                nav.classList.add("open");
            } else if (e.target.nodeName==="A") {
                nav.classList.remove("open");
                navigate(e.target.attributes.href.value);
            }

            e.stopPropagation();
        }

        nav.addEventListener("mouseover", openMenu);
        nav.addEventListener("mouseout", closeMenu);
        nav.addEventListener("touchend", toggleMenu);
        window.addEventListener("touchend", mobileCloseMenu);

        return () => {
            nav.removeEventListener("mouseover", openMenu);
            nav.removeEventListener("mouseout", closeMenu);
            nav.removeEventListener("touchend", toggleMenu);
            window.addEventListener("touchend", mobileCloseMenu);
        };
    }, []);
}