/**
[header]
@author ertdfgcvb
@title  Camera RGB
@desc   Color input from camera (quantised)
*/

import { rgb2hex, rgb}  from '../../modules/color.js'
import Camera from '../../modules/camera.js'
import Canvas from '../../modules/canvas.js'

// Desktop
const ASCIIS_DESKTOP = [
	{ bgColor: rgb(  0,   0,   0), textColor: 'rgb(255, 255, 255)', textCharacter: 'OFFLINE ONLY  ' },
	{ bgColor: rgb(  0,   0,   0), textColor: 'rgb(255, 255, 255)', textCharacter: '·  ' },
	{ bgColor: rgb(  0,   0,   0), textColor: 'rgb(255, 255, 255)', textCharacter: 'thisisneverthat®     ' },
	{ bgColor: rgb(255, 255, 255), textColor: 'rgb(  0,   0,   0)', textCharacter: '·' },
	{ bgColor: rgb(255, 255, 255), textColor: 'rgb(  0,   0,   0)', textCharacter: '✦  -  ' },
	{ bgColor: rgb(255, 255, 255), textColor: 'rgb(  0,   0,   0)', textCharacter: '@  =  ' }
];

// Mobile
const ASCIIS_MOBILE = [
	{ bgColor: rgb(  0,   0,   0), textColor: 'rgb(255, 255, 255)', textCharacter: 'OFFLINE ONLY' },
	{ bgColor: rgb(  0,   0,   0), textColor: 'rgb(255, 255, 255)', textCharacter: '·' },
	{ bgColor: rgb(  0,   0,   0), textColor: 'rgb(255, 255, 255)', textCharacter: 'thisisneverthat®   ' },
	{ bgColor: rgb(255, 255, 255), textColor: 'rgb(  0,   0,   0)', textCharacter: '·' },
	{ bgColor: rgb(255, 255, 255), textColor: 'rgb(  0,   0,   0)', textCharacter: '✦ - ' },
	{ bgColor: rgb(255, 255, 255), textColor: 'rgb(  0,   0,   0)', textCharacter: '@ = ' }
];

const ASCIIS_LOADING = [
	{ bgColor: '#fff', textColor: '#000', textCharacter: ' ' },
	{ bgColor: '#fff', textColor: '#000', textCharacter: '·  ' },
	{ bgColor: '#fff', textColor: '#000', textCharacter: '/' },
	{ bgColor: '#fff', textColor: '#000', textCharacter: 'thisisneverthat®' },
	{ bgColor: '#fff', textColor: '#000', textCharacter: '✦  -  ' },
	{ bgColor: '#fff', textColor: '#000', textCharacter: '@  =  ' }
];

class CameraRGB {
	constructor(facingMode='user', setLoading, isMobile=false) {
		this.settings = {
			fontFamily: "TransylvanianBold"
		};
		this.cam = new Camera(facingMode, setLoading);
		this.can = new Canvas();
		this.data = [];
		this.cache = { prevWord: undefined, prevPos: 0, prevIndex: undefined };

		this.initPalette(isMobile);

		this.facingMode = facingMode;
	}

	initPalette(isMobile) {
		const ASCIIS = (isMobile ? ASCIIS_MOBILE : ASCIIS_DESKTOP);
		this.density    = Object.keys(ASCIIS).map(el => ASCIIS[el].textCharacter);
		this.pal        = Object.keys(ASCIIS).map(el => ASCIIS[el].bgColor);
		this.textColors = Object.keys(ASCIIS).map(el => ASCIIS[el].textColor);
		
		this.palToCompare = [...Array(this.pal.length).keys()].map(el => {
			const pixRGB = Math.min(parseInt(256 / (this.pal.length - 1)) * el, 255);
			return rgb(pixRGB, pixRGB, pixRGB);
		});
	}

	pre(context, buffer) {
		const a = context.metrics.aspect;
	
		// The canvas is resized so that 1 cell -> 1 pixel
		this.can.resize(context.cols, context.rows);
		// The cover() function draws an image (cam) to the canvas covering
		// the whole frame. The aspect ratio can be adjusted with the second
		// parameter.
		if (this.facingMode === 'environment') {
			this.can.cover(this.cam.video, a).quantize(this.pal, this.palToCompare).writeTo(this.data);
		} else {
			this.can.cover(this.cam.video, a).mirrorX().quantize(this.pal, this.palToCompare).writeTo(this.data);
		}
	}

	main(coord, context, buffer) {
		// Coord also contains the index of each cell
        const { index } = this.data[coord.index];

        let textColor = this.textColors[index];

        let currWord;
        let currChar;
        let currPos;
        let currColor = this.pal[index];

        if(index === this.cache.prevIndex) {
            currWord = this.cache.prevWord;
            currPos = (this.cache.prevPos + 1) % currWord.length;
            currChar = this.cache.prevWord[currPos];
            this.cache.prevPos = currPos;
        } else {
            currWord = this.density[index];
            currChar = currWord[0];
            this.cache.prevWord = currWord;
            this.cache.prevIndex = index;
        }

        if(currChar==='.' && this.cache.prevWord==='.') {textColor = 'rgb(0, 0, 0)';}
        else if(currChar==='_' && this.cache.prevWord==='_') {textColor = 'rgb(0, 0, 0)';}

        return {
            char       : currChar,
            color      : textColor,
            backgroundColor : rgb2hex(currColor),
            wordIndex  : index
        }
	}
	
	post(context, buffer) {
		// drawInfo(context, cursor, buffer)
	}
}

export default CameraRGB;