/**
@module   camera.js
@desc     Webcam init and helper
@category public

Initializes a user-facing camera,
returns a video element (initialised asynchronously).
*/

class Camera {
	constructor(facingMode='user', setLoading) {
		this.video = this.video || this.getUserMedia(facingMode, setLoading);
	}

	getUserMedia(facingMode, setLoading) {
		// getUserMedia is not supported by browser
		if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
			throw new DOMException('getUserMedia not supported in this browser')
			return
		}

		// Create a video element
		const video = document.createElement('video')
		video.setAttribute('playsinline', '') // Required to work in iOS 11 & up

		const constraints = {
			audio: false,
			video: { facingMode: facingMode }
		}

		navigator.mediaDevices.getUserMedia(constraints).then(function(stream) {
			// setLoading false once get permission for camera
			if ('srcObject' in video) {
				video.srcObject = stream
			} else {
				video.src = window.URL.createObjectURL(stream)
			}
			// setLoading false once get permission for camera
			setTimeout(() => {
				setLoading(false);
			}, 2000);
		}).catch(function(err) {
			let msg = 'No camera available.'
			if (err.code == 1) msg = 'User denied access to use camera.'
			console.log(msg);
			console.error(err)
		})

		video.addEventListener('loadedmetadata', function() {
			video.play()
			// if (typeof callback === 'function') callback(video.srcObject)
		})
		return video
	}
}

export default Camera;