import { Outlet } from 'react-router-dom';

import Header from './Header/component';

const Layout = () => {
    return (
        <div className="app panel98">
            <div className="wrapper">
                <Header/>
                <Outlet/>
            </div>
        </div>
    );
};

export default Layout;