const Exhibition = () => {
    return (
        <div className="expo">
            <div className="expo-wrapper">
                <div className="content">
                    <h2>thisisneverthat®<br/>Fall/Winter 2022 Exhibition<br/>OFFLINE ONLY</h2>

                    <div className="description">
                        <p>thisisneverthat®이 공개하는 Fall/Winter 2022 ‘OFFLINE ONLY’ 컬렉션의 오프라인 전시.</p>
                        <p>‘OFFLINE ONLY’로 구성된 ASCII 인터랙티브 미디어월을 시작으로 새로운 시즌의 디스이즈네버댓을 선보입니다.</p>
                        <p>무신사테라스 성수에서 포토부스, DJ 파티도 즐기고 익스클루시브 티와 선착순 기프트도 만나보세요.</p>
                        <br/>
                        <p>• 2022/08/19(금) – 08/21(일) (11:00 – 21:00)</p>
                        <p><a href="http://kko.to/mioDnKhWI" target="_blank">• 서울 성동구 아차산로 104 3층 무신사테라스 성수</a></p>
                    </div>

                    <a className="btn98 calendar" href="https://jknd-yeojin.s3.ap-northeast-2.amazonaws.com/thisisneverthatFW22.ics">Add to calendar</a>
                </div>

                <img src={require("../assets/images/poster/poster_front.png")} alt="poster" />
                <img src={require("../assets/images/poster/poster_back.png")} alt="poster" />

                <img className="offline" src={require("../assets/images/poster/title.png")} alt="offline" />

                <span className="year">© 2022</span>
            </div>
        </div>
    );
}

export default Exhibition;